import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'currencySymbol',
  pure: false
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(currencyCode: string): any {
    if (!currencyCode) {
      return '';
    }

    let symbol = '';
    if (currencyCode === 'EUR') {
      symbol = '€';
    }
    else if (currencyCode === 'USD') {
      symbol = '$';
    }
    else if (currencyCode === 'ILS') {
      symbol = '₪';
    }

    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return symbol;
  }
}
