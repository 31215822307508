import {NgModule} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {NotifyDialogComponent} from './notify-dialog/notify-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    NotifyDialogComponent
  ],
  imports: [
    MatDialogModule,
    MatIconModule
  ]
})
export class NotificationsModule {}
