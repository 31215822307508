import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import AppService from '../home/app.service';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {UserProfileComponent} from '../user-profile/user-profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() toggle: boolean;
  @Output() toggleChange = new EventEmitter<boolean>(false);

  logo: string;
  displayName: string;
  private _session: Subscription;

  constructor(private auth: AuthService, private router: Router, private appService: AppService, private dialog: MatDialog) {
    this._session = this.appService.updated.subscribe(() => {
      this.setSettings();
    });
  }

  private setSettings() {
    this.logo = this.appService.settings?.logo;
    this.displayName = this.appService.settings?.fullName || this.appService.settings?.email;
  }

  ngOnInit(): void {
    this.setSettings();
  }

  editProfile() {
    const dialogRef = this.dialog.open(UserProfileComponent, {panelClass: 'no-pad-dialog'});
  }

  logoutUser() {
    this.auth.logout();
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this._session.unsubscribe();
  }


  toggleMenu() {
    // console.log('toggled');
    this.toggle = !this.toggle;
    this.toggleChange.emit(this.toggle);
  }
}
