import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.scss', '../auth.module.scss']
})
export class LoginComponent implements OnInit {

  userData: {email: string, password: string} = {email: '', password: ''};
  loading = false;
  error = false;

  constructor(private auth: AuthService, private route: Router) {

  }

  ngOnInit(): void {

  }

  onSubmit(form: NgForm): void {
    if (!form.errors) {
      this.loading = true;
      this.auth.logUser(this.userData.email, this.userData.password)
        .subscribe((data) => {
          this.loading = false;
          this.route.navigate(['/']);
        }, (e) => {
          this.loading = false;
          this.error = true;
          console.log(e.message);
        });
    }
  }

}
