import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptorService} from '../auth/auth.interceptor';
import {FilterPipe} from './pipes/filter.pipe';
import { CircleChartComponent } from './circle-chart/circle-chart.component';
import {CurrencySymbolPipe} from './pipes/currency-symbol.pipe';
import {PixelCodeNamePipe} from './pipes/pixel-code-name.pipe';
import {LangCodeFull} from './pipes/lang-code-full';

@NgModule({
  declarations: [
    FilterPipe,
    CircleChartComponent,
    CurrencySymbolPipe,
    PixelCodeNamePipe,
    LangCodeFull
  ],
  exports: [
    CircleChartComponent,
    FilterPipe,
    CurrencySymbolPipe,
    PixelCodeNamePipe,
    LangCodeFull
  ],
  imports: [
    FormsModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    HttpClientModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }]
})
export class HelpersModule {}
