import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {environment} from '../../environments/environment';


@Injectable({providedIn: 'root'})
export default class AppService {

  _settings: any;
  _statistics: any;
  updated: EventEmitter<any> = new EventEmitter<any>();
  statsUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) {
  }

  loadSettings() {
    this.http.get(`${environment.serverUrl}/user/session`).subscribe(result => {
      this._settings = result;
      this.updated.emit();
    }, e => {

    });
  }

  loadStatistics() {
    this.http.get(`${environment.serverUrl}/organization/statistics`).subscribe(result => {
      this._statistics = result;
      this.statsUpdated.emit();
    }, e => {

    });
  }

  get settings() {
    return this._settings;
  }

  get statistics() {
    return this._statistics;
  }

  hasUpdate() {
    this.updated.emit();
  }

}
