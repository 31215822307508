<div class="container" style="max-width: 500px; margin-top: 80px;">
  <h1>Reset password</h1>
  <div *ngIf="!validKey && !noToken">
    Validating
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M17 50A33 33 0 0 0 83 50A33 37.6 0 0 1 17 50" fill="#fff" stroke="none" transform="rotate(328.237 50 52.3)">
          <animateTransform attributeName="transform" type="rotate" dur="1.0416666666666665s" repeatCount="indefinite" keyTimes="0;1" values="0 50 52.3;360 50 52.3"></animateTransform>
        </path>
      </svg>
  </div>
  <div *ngIf="noToken">
    Request is invalid, please try to reset your password again<br>
    <a routerLink="/auth/reset-password">Reset password</a> | <a routerLink="/auth/login">Login</a>
  </div>
  <div *ngIf="validKey && !noToken">
    <form (ngSubmit)="onSubmit()" [formGroup]="setPasswordForm">
      <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" aria-describedby="passwordHelp" formControlName="password" minlength="3">
      </div>
      <div class="form-group">
        <label>Confirm password</label>
        <input type="password" class="form-control" aria-describedby="passwordHelp" formControlName="confirmPassword" minlength="3">
      </div>
      <button type="submit" class="btn btn-success">Reset</button>
    </form>
  </div>
</div>
