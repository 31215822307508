import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuard, UnAuthGuard} from './auth/auth.guard';
import {MainViewComponent} from './home/main-view/main-view.component';


const routes: Routes = [
  {
    path: 'auth', canActivate: [UnAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  { path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
      { path: 'campaigns', loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule) },
      { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule) },
      { path: 'mailing', loadChildren: () => import('./mailing/mailing.module').then(m => m.MailingModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
      { path: '', pathMatch: 'full', component: MainViewComponent }
  ]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
