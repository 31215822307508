<div class="login-box">
    <h1 class="font-weight-normal">התחברות</h1>
    <p>נא להזין את פרטי המשתמש להתחברות</p>
    <form #loginForm="ngForm">
        <div class="input-group mcb-10">
            <input type="text" class="form-control right-icon" name="email" aria-describedby="emailHelp" autocomplete="off" [(ngModel)]="userData.email" [ngModelOptions]="{standalone: true}" email>
            <div class="input-group-img">
                <img src="/assets/auth/person.svg" alt="profile login">
            </div>
        </div>
        <div class="input-group mb-1">
            <input type="password" class="form-control right-icon text-left" aria-describedby="passwordHelp" placeholder="" name="password" autocomplete="off" [ngModelOptions]="{standalone: true}" [(ngModel)]="userData.password">
            <div class="input-group-img">
                <img src="/assets/auth/key.svg" alt="profile login">
            </div>
        </div>
        <div class="d-flex">
            <span *ngIf="error" class="error-msg ml-3">שם משתמש או סיסמא שגויים</span>
            <a routerLink="/auth/reset-password" class="btn btn--link-light font-small text-left mr-auto">שכחתי סיסמא</a>
        </div>
        <button type="button" (click)="onSubmit(loginForm)" class="btn btn--blue mt-5" [disabled]="loginForm.invalid || loading">
            <span *ngIf="!loading">התחברות</span>
            <span *ngIf="loading">
                מתחבר
                 <img src="/assets/general/btn-load.svg">
            </span>
        </button>
    </form>
</div>
<!--<div class="container-auth">-->
<!--    <div class="wrapper">-->
<!--        <h1>GEEV</h1>-->
<!--        <form class="login-form" #loginForm="ngForm">-->
<!--            <div class="row">-->
<!--                <mat-form-field class="full-width-field">-->
<!--                    <mat-label>Email</mat-label>-->
<!--                    <input type="email" matInput id="loginUser" aria-describedby="emailHelp" [(ngModel)]="userData.email" [ngModelOptions]="{standalone: true}" email>-->
<!--                </mat-form-field>-->
<!--            </div>-->
<!--            <div class="row mt-3">-->
<!--                <mat-form-field class="full-width-field">-->
<!--                    <mat-label>Password</mat-label>-->
<!--                    <input type="password" matInput id="loginUserPwd" aria-describedby="emailHelp" [ngModelOptions]="{standalone: true}" [(ngModel)]="userData.password">-->
<!--                </mat-form-field>-->
<!--            </div>-->
<!--            <div class="row mt-3">-->
<!--                <button mat-button mat-flat-button color="primary" style="width: 100%;" (click)="onSubmit(loginForm)">-->
<!--                    <span *ngIf="!loading">Login</span>-->
<!--                    <span *ngIf="loading">-->
<!--                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">-->
<!--                            <path d="M17 50A33 33 0 0 0 83 50A33 37.6 0 0 1 17 50" fill="#fff" stroke="none" transform="rotate(328.237 50 52.3)">-->
<!--                              <animateTransform attributeName="transform" type="rotate" dur="1.0416666666666665s" repeatCount="indefinite" keyTimes="0;1" values="0 50 52.3;360 50 52.3"></animateTransform>-->
<!--                            </path>-->
<!--                        </svg>-->
<!--                    </span>-->
<!--                </button>-->
<!--            </div>-->
<!--        </form>-->
<!--        <div class="notes">-->
<!--            Don't have an account yet? <a routerLink="/auth/signup">Signup</a> | <a routerLink="/auth/reset-password">Forgot password</a>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
