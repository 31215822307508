<app-header [(toggle)]="menuActive"></app-header>
<div class="wrapper">
  <div class="header-mobile">
    <div class="container">
      <div class="d-flex">
        <div class="burger" [class]="{'sticky-bar-active': menuActive}">
          <div class="burger-opener" (click)="toggleMenu()"><span></span></div>
        </div>
        <img class="logo-mobile" src="/assets/general/g.svg" alt="הצג תפריט" title="הצג תפריט">
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
