import {Component, OnDestroy, OnInit} from '@angular/core';
import AppService from '../app.service';
import {Subscription} from 'rxjs';
import {MailingStatus, MailingStatusHeb} from '../../mailing/mailing-interfaces';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit, OnDestroy {

  generalUpdated: Subscription;
  statsUpdated: Subscription;
  displayName: string;
  statistics: any;
  mailingStatusesHeb = MailingStatusHeb;
  percentsFromLastWeek = 0;
  loading = true;
  delay = false;

  constructor(private appService: AppService, private _datePipe: DatePipe) {
    this.generalUpdated = this.appService.updated.subscribe(() => {
      this.loadDisplayName();
    });
    this.statsUpdated = this.appService.statsUpdated.subscribe(() => {
      this.statistics = this.appService.statistics;
      this.loading = false;
      if (this.statistics && this.statistics.totals && this.statistics.totals.sum_week && this.statistics.totals.sum_prev_week) {
        this.percentsFromLastWeek = Math.abs(100 - Math.round((this.statistics.totals.sum_week / this.statistics.totals.sum_prev_week * 100)));
      }
    });
  }

  ngOnInit(): void {
    this.loadDisplayName();
    this.loadStatistics();
  }

  loadDisplayName() {
    this.displayName = this.appService.settings?.fullName || this.appService.settings?.email;
  }

  ngOnDestroy(): void {
    this.generalUpdated.unsubscribe();
    this.statsUpdated.unsubscribe();
  }

  private loadStatistics() {
    this.appService.loadStatistics();
  }

  getMailingTooltip(mailing: any) {
    if (mailing.status === MailingStatus.Scheduled) {
      const date = this._datePipe.transform(mailing.timing, 'dd/MM/yyyy');
      const time = this._datePipe.transform(mailing.timing, 'HH:mm');
      return `מתוזמן לתאריך ${date} בשעה ${time}`;
    }
    return '';
  }
}
