import {RecipientsTypes} from './manage-mailing/manage-mailing.component';
import {ContactsType} from './manage-mailing/manage-mailing.component';

export interface Mailing {
  enableLinks: boolean;
  id?: string;
  subject?: string;
  isSms?: boolean;
  replyTo?: string;
  sender?: string;
  title?: string;
  logo?: string;
  content?: string;
  phone?: string;
  email?: string;
  facebook?: string;
  twitter?: string;
  website?: string;
  timing?: string;
  sendNow?: boolean;
  organizationId?: string;
  status?: number;
  mailingCampaignId?: string;
  isArchived?: boolean;
  isDeleted?: boolean;
  labels?: {name: string}[];
  recipients?: string[];
  recipientsType?: RecipientsTypes;
  pageId?: string;
  page?: {id: string, title: string, campaignId: string};
  contactsSummary?: any;
  contactsType?: ContactsType;
  contactsAllLanguages?: boolean;
  lang?: string;
  campaignId?: string;
}

export enum MailingStatus {
  Draft,
  Scheduled,
  Sent,
  Archived
}

export enum MailingStatusHeb {
  טיוטה,
  מתוזמן,
  נשלח,
  ארכיון
}

export interface TimingDialogData {
  timing: string;
}
