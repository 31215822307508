<div class="login-box">
  <h1 class="font-weight-normal">שחזור סיסמא</h1>
  <p>הזינו את האימייל שמקושר לחשבון בכדי לאפס סיסמא</p>
  <form name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div *ngIf="success" class="pt-4">איפוס סיסמא נשלח לדוא"ל בהצלחה</div>
    <div class="input-group mcb-10">
      <input type="text" class="form-control" placeholder="" name="email" autocomplete="off" formControlName="email" email>
    </div>
    <div class="pt-4">
      <button type="submit" class="btn btn--blue mb-1 mr-auto ml-auto">איפוס חשבון</button>
      <button routerLink="/auth/login" type="button" class="btn btn--link d-block mt-3 mr-auto ml-auto">חזרה להתחברות</button>
    </div>
  </form>
</div>
