import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filter: {[s: string]: string}): any {
    if (!items || !filter || (filter && !filter.name)) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.name.toLowerCase().indexOf(filter.name.toLowerCase()) !== -1);
  }
}
