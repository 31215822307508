import { Component, OnInit } from '@angular/core';
import AppService from './app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  menuActive = false;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.loadSettings();
  }

  toggleMenu() {
    this.menuActive = !this.menuActive;
  }

}
