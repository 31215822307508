import {NgModule} from '@angular/core';
import {AppRoutingModule} from './auth.routing';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {PasswordResetComponent} from './passwordreset/passwordreset.component';
import {SetPasswordComponent} from './set-password/set-password.component';
import { MainComponent } from './main/main.component';

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    PasswordResetComponent,
    SetPasswordComponent,
    MainComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AuthModule {}
