<div class="user-profile-dialog">
  <div class="header-row">
    <div class="title">עריכת פרופיל</div>
    <button class="close" (click)="close()"><i class="icon-cancel"></i></button>
  </div>
  <div class="content">
    <form #f="ngForm">
      <div class="separate-row mb-3">
        <div class="form-line" *ngIf="error">
          סיסמא נוכחית שגויה
        </div>
        <div class="form-line">
          <label for="firstName">שם מלא</label>
          <input id="firstName" type="text" [(ngModel)]="profile.fullName" name="fullName">
        </div>
        <div class="form-line">
          <label for="email">דוא"ל</label>
          <input id="email" type="text" [(ngModel)]="profile.email" name="email" disabled>
        </div>
      </div>
    
      <div class="form-line">
        <label for="pwdCurrent">סיסמא נוכחית</label>
        <input id="pwdCurrent" type="password" [(ngModel)]="pwd.current" name="currentPassword" minlength="6">
      </div>
      <div class="form-line" *ngIf="pwd.current">
        <label for="pwdNew">סיסמא חדשה</label>
        <input id="pwdNew" type="password" [(ngModel)]="pwd.newp" name="newPassword" minlength="6">
      </div>
      <div class="form-line" *ngIf="pwd.newp">
        <label for="pwdConfirmNew">אימות סיסמא חדשה</label>
        <input id="pwdConfirmNew" type="password" [(ngModel)]="pwd.confirm" name="confirmNewPass" minlength="6">
      </div>
  
      <div class="btn-row">
        <button (click)="save()" class="main" [disabled]="saving || f.invalid">שמירת שינויים</button>
        <button (click)="close()" [disabled]="saving">ביטול</button>
      </div>
      
    </form>
    
  </div>
</div>
