import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {SetPasswordComponent} from './set-password/set-password.component';
import {PasswordResetComponent} from './passwordreset/passwordreset.component';
import {MainComponent} from './main/main.component';


const routes: Routes = [
  { path: '', component: MainComponent, children: [
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'reset-password', component: PasswordResetComponent },
    { path: 'set', component: SetPasswordComponent },
    { path: 'set/:token/:email', component: SetPasswordComponent }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
