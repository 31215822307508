import {Pipe, PipeTransform} from '@angular/core';


const LANGS = {
  he: 'עברית',
  en: 'אנגלית',
  fr: 'צרפתית',
  ru: 'רוסית',
  es: 'ספרדית',
  ar: 'ערבית'
};

@Pipe({
  name: 'langCode',
  pure: false
})
export class LangCodeFull implements PipeTransform {
  transform(langCode: string): any {
    if (!langCode) {
      return '';
    }
    return LANGS[langCode] ? LANGS[langCode] : langCode;
  }
}
