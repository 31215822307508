<div class="page-wrapper">
  <div class="mw-100 mt-auto mb-auto text-center pt-5 pb-5">
    <a href="#"><img src="/assets/auth/logo.svg" class="logo" alt="" /></a>
      <router-outlet></router-outlet>
  </div>
</div>
<div class="bottom-banner">
  <p class="text-white m-0">אין לכם חשבון איתנו?</p>
  <a href="#" class="btn btn--small btn--transparent-white mr-3">שלחו לנו בקשה</a>
</div>
