import {Pipe, PipeTransform} from '@angular/core';


const PIXEL_NAMES = {
  fb: 'Facebook',
  tbl: 'Taboola',
  obr: 'Outbrain',
  tkt: 'Tiktok',
  ga: 'Google Analytics',
  gads: 'Google Ads',
  ydx: 'Yandex'
};

@Pipe({
  name: 'pixelCode',
  pure: false
})
export class PixelCodeNamePipe implements PipeTransform {
  transform(pixelCode: string): any {
    if (!pixelCode) {
      return '';
    }
    return PIXEL_NAMES[pixelCode] ? PIXEL_NAMES[pixelCode] : pixelCode;
  }
}
