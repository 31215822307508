import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import AppService from '../home/app.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  profile: any;
  private _settingsSubscriber: Subscription;
  loading = true;
  saving = false;
  pwd: {current?: string, newp?: string, confirm?: string} = {};
  error = false;

  constructor(private dialogRef: MatDialogRef<UserProfileComponent>, private appService: AppService, private http: HttpClient, private auth: AuthService) {
    this._settingsSubscriber = this.appService.updated.subscribe(() => {
      this._setValues();
    });
  }

  private _setValues() {
    this.profile = {
      email: this.appService.settings?.email,
      fullName: this.appService.settings?.fullName
    };

    if (this.profile.email) {
      this.loading = false;
    }
  }

  ngOnInit(): void {
    this._setValues();
  }

  save() {
    this.saving = true;
    this.http.put(`${environment.serverUrl}/user/me`, {...this.profile, pwd: this.pwd}).subscribe((resp: any) => {
      this.appService._settings.fullName = this.profile.fullName;
      this.appService._settings.email = this.profile.email;
      this.appService.hasUpdate();
      this.dialogRef.close();
    }, e => {
      this.saving = false;
      this.error = true;
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._settingsSubscriber.unsubscribe();
  }


}
