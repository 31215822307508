import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.scss']
})
export class CircleChartComponent implements OnInit {

  graphColor = 0;
  prec = 0;

  @Input() set model(s: string) {
   if (+s) {
     this.prec = +s;
     if (+s > 30 && +s < 60) {
       this.graphColor = 1;
     }
     else if (+s > 60) {
       this.graphColor = 2;
     }
   }
  }

  constructor() { }

  ngOnInit(): void {
  }

}
