<main role="main" id="main">
  <div class="main-loader" *ngIf="loading">
    <img src="/assets/general/main-loader.gif">
  </div>
  <div class="container" *ngIf="!loading">
    <div class="top-banner">
      <div class="title-row">
        שלום
        <span class="bold">{{displayName}}</span>,
        השבוע גייסתם
        <br>
      </div>
      <div class="total-weekly">
        {{+statistics?.totals?.sum_week | currency:'ILS':'symbol':'1.0-0'}}
      </div>
      <div class="stats-content" *ngIf="statistics?.totals?.sum_today">
        {{+statistics?.totals?.sum_today | currency:'ILS':'symbol':'1.0-0'}} מתוכם ב24 השעות האחרונות
      </div>
      <div class="compared" *ngIf="+percentsFromLastWeek > 0">
        גייסתם {{percentsFromLastWeek}}% {{+statistics?.totals?.sum_week > +statistics?.totals?.sum_prev_week ? 'יותר' : 'פחות'}} מבשבוע שעבר
      </div>
    </div>
    <div class="main-stats box">
      <div class="single-stat main">
        <div class="info">
          <span class="stat-data">{{+statistics?.totals?.sum_ils | currency:'ILS':'symbol':'1.0-0'}}</span>
          <span class="stat-desc">סה"כ גויס בחשבון</span>
          <div class="currencies">
            <button matTooltip="${{+statistics?.donations?.sum_usd}} מתוך הסכום הכללי">$</button>
            <button matTooltip="€{{+statistics?.donations?.sum_eur}} מתוך הסכום הכללי">€</button>
          </div>
        </div>
        <i class="icon-hand-heart"></i>
      </div>
      <div class="single-stat">
        <span class="stat-data">{{+statistics?.lastDonation?.amount_ils | currency:'ILS':'symbol':'1.0-0'}}</span>
        <span class="stat-desc">תרומה אחרונה</span>
      </div>
      <div class="single-stat">
        <span class="stat-data">{{+statistics?.totals?.count_donations}}</span>
        <span class="stat-desc">תרומות</span>
      </div>
      <div class="single-stat">
        <span class="stat-data">{{+statistics?.activeCampaigns?.count_supporters}}</span>
        <span class="stat-desc">שגרירים</span>
      </div>
      <div class="single-stat">
        <span class="stat-data">{{+statistics?.donations?.subscriptions}}</span>
        <span class="stat-desc">הוראות קבע</span>
      </div>
    </div>
    <div class="shortcuts">
      <div class="box" routerLink="/campaigns/set">
        <div class="stats-info">
          <span class="stat-data">{{+statistics?.activeCampaigns?.count_campaigns}}</span>
          <span class="stat-desc">
            קמפיינים פעילים<br>
            {{+statistics?.activeCampaigns?.count_pages}} <span class="lite">דפים</span>
          </span>
        </div>
        <i class="icon-campaigns"></i>
        <button class="add-btn"><i class="icon-plus"></i></button>
      </div>
      <div class="box" routerLink="/mailing/add">
        <div class="stats-info">
          <span class="stat-data">{{+statistics?.mailings?.emails}}</span>
          <span class="stat-desc">
            סה"כ דיוורים<br>
            {{+statistics?.mailings?.delivered_mails}} <span class="lite">מיילים נשלחו</span>
          </span>
        </div>
        <i class="icon-mailing"></i>
        <button class="add-btn"><i class="icon-plus"></i></button>
      </div>
      <div class="box" routerLink="/mailing/add" [queryParams]="{isSms: true}">
        <div class="stats-info">
          <span class="stat-data">{{+statistics?.mailings?.sms}}</span>
          <span class="stat-desc">
            סה"כ מסרונים<br>
            {{+statistics?.mailings?.delivered_sms}} <span class="lite">מסרונים נשלחו</span>
          </span>
        </div>
        <i class="icon-sms"></i>
        <button class="add-btn"><i class="icon-plus"></i></button>
      </div>
      <div class="box" routerLink="/contacts/set">
        <div class="stats-info">
          <span class="stat-data">{{+statistics?.contacts?.count}}</span>
          <span class="stat-desc">
            אנשי קשר<br>
            {{+statistics?.contacts?.important}} <span class="lite">מתוכם תרמו מעל פעמיים</span>
          </span>
        </div>
        <i class="icon-user"></i>
        <button class="add-btn"><i class="icon-plus"></i></button>
      </div>
    </div>
    <div class="last-tables">
      <div class="box" *ngIf="statistics?.recentCampaigns?.length > 0">
        <div class="title">קמפיינים אחרונים</div>
        <ul class="table" *ngIf="statistics && statistics.recentCampaigns">
          <li *ngFor="let campaign of statistics.recentCampaigns">
            <div>{{campaign.name}}</div>
            <div>{{campaign.created_at | date: 'dd/MM/yyyy'}}</div>
            <div>{{campaign.pages}} דפים</div>
            <div>
              <span class="amount">{{campaign.amount | currency:campaign.currency:'symbol':'1.0-0'}}</span> / {{campaign.total || 0 | currency:campaign.currency:'symbol':'1.0-0'}}
            </div>
          </li>
        </ul>
      </div>
      <div class="box" *ngIf="statistics?.recentMailings?.length > 0">
        <div class="title">דיוורים אחרונים</div>
        <ul class="table mailing" *ngIf="statistics && statistics.recentMailings">
          <li *ngFor="let mailing of statistics.recentMailings" [matTooltip]="getMailingTooltip(mailing)">
            <div>
              <i *ngIf="mailing.is_sms" class="icon-sms"></i>
              <i *ngIf="!mailing.is_sms" class="icon-mailing"></i>
            </div>
            <div>{{mailing.title}}</div>
            <div>
              {{mailing.delivered || 0}} אנשי קשר
            </div>
            <div>{{mailingStatusesHeb[mailing.status]}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</main>
