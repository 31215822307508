import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { environment } from '../../environments/environment';

export interface User {
  email: string;
  password: string;
  fullName?: string;
}

export interface UserSalt {
  salt: string;
}

@Injectable({providedIn: 'root'})
export class AuthService {

  public userData: User;
  private http: HttpClient;
  private _token: string;
  private userValid: boolean;

  constructor(handler: HttpBackend, private route: Router) {
    this.http = new HttpClient(handler);
    this.isUserValid = false;

    this.autoLogin();
  }

  set token(token: string) {
    this._token = token;
  }

  get token(): string {
    return this._token;
  }

  get currentUser() {
    return this.userData;
  }

  handleAuth(data: {[s: string]: string}) {
    this.token = data.token;
    this.isUserValid = true;
  }

  handleLogin(userData) {
    if (userData && userData.token) {
      localStorage.setItem('_uToken', userData.token);
      this.handleAuth({token: userData.token});
    }
  }

  autoLogin() {
    const token = localStorage.getItem('_uToken');
    if (token) {
      this.handleAuth({token});
    }
  }

  logUser(email, password) {
    this.userData = {
      email, password
    };

    if (this.userData.email && this.userData.password) {
      return this.http.post(`${environment.serverUrl}/auth/login`, this.userData)
        .pipe(tap(resData => this.handleLogin(resData)));
    }
  }

  logUserBySalt(salt) {
    if (salt) {
      return this.http.post(`${environment.serverUrl}/users/auth/token`, {salt})
        .pipe(tap(resData => this.handleLogin(resData)));
    }
  }

  registerUser(userInfo) {
    if (userInfo.email && userInfo.password) {
      this.userData = {email: userInfo.email, password: userInfo.password, fullName: userInfo.fullName};

      return this.http.post(`${environment.serverUrl}/users/register`, userInfo)
        .pipe(tap(resData => this.handleLogin(resData)));
    }
  }

  logout() {
    this.userData = {email: '', password: ''};
    localStorage.removeItem('_uToken');
    this.isUserValid = false;
    this.route.navigate(['/auth/login']);
  }

  get isUserValid() {
    return this.userValid;
  }

  set isUserValid(bool: boolean) {
    this.userValid = bool;
  }

  resetPasswordToken(email: string) {
    if (email) {
      return this.http.post(`${environment.serverUrl}/auth/reset/token`, {email});
    }
  }

  validateResetToken(token: string, email: string) {
    if (token && email) {
      return this.http.post(`${environment.serverUrl}/auth/reset/token/validate`, {token, email});
    }
  }

  resetPassword(token: string, email: string, newPass: string, confirmPass: string) {
    if (token && email && newPass && confirmPass) {
      return this.http.put(`${environment.serverUrl}/auth/reset/password`, {token, email, newPass, confirmPass});
    }
  }

}
