<div class="single-chart" *ngIf="prec">
  <svg viewBox="0 0 36 36" class="circular-chart" [ngClass]="{'green': graphColor == 2, 'orange': graphColor == 1, 'blue': graphColor == 0}">
    <path class="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
    />
    <path class="circle" attr.stroke-dasharray="{{prec}},100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
    <text x="18" y="20.35" class="percentage">{{prec}}</text>
  </svg>
</div>
