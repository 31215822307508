import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  userData: {email: string, password: string} = {email: '', password: ''};
  loading = false;
  errors: {};

  constructor(private auth: AuthService, private route: Router) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm): void {
    if (!form.errors && form.dirty) {
      this.loading = true;
      this.auth.registerUser({email: form.controls.email.value, password: form.controls.password.value, organization: form.controls.organizationName.value, fullName: form.controls.fullName.value })
        .subscribe((data) => {
          this.loading = false;
          this.route.navigate(['/']);
        }, (e) => {
          this.loading = false;
          console.log(e.message);
        });
    }
  }

}
