<div class="container" style="max-width: 500px; margin-top: 80px;">
  <h1>GEEV SIGN UP</h1>
  <form (ngSubmit)="onSubmit(signupForm)" #signupForm="ngForm">
    <div class="form-group">
      <label for="fullNameInput">Full Name</label>
      <input type="text" class="form-control" id="fullNameInput" aria-describedby="nameHelp" ngModel name="fullName">
    </div>
    <div class="form-group">
      <label for="organizationName">Organization name</label>
      <input type="text" class="form-control" id="organizationName" aria-describedby="orgNameHelp" ngModel name="organizationName">
    </div>
    <div class="form-group">
      <label for="emailInput">Email *</label>
      <input type="email" class="form-control" id="emailInput" aria-describedby="emailHelp" ngModel name="email" email>
    </div>
    <div class="form-group">
      <label for="passwordHelp">Password *</label>
      <input type="password" class="form-control" id="passwordHelp" aria-describedby="passwordHelp" ngModel name="password" minlength="5" #passwordField>
    </div>
    <div class="form-group" *ngIf="passwordField.value">
      <label for="passwordConfHelp">Confirm password</label>
      <input type="password" class="form-control" id="passwordConfHelp" aria-describedby="passwordConfHelp" ngModel name="confirm_password" minlength="5">
    </div>
    <button type="submit" class="btn btn-primary">
      Sign up
      <!--      <span *ngIf="!loading"></span>-->
      <!--      <svg *ngIf="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="18px" height="18px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">-->
      <!--        <path d="M17 50A33 33 0 0 0 83 50A33 37.6 0 0 1 17 50" fill="#fff" stroke="none" transform="rotate(328.237 50 52.3)">-->
      <!--          <animateTransform attributeName="transform" type="rotate" dur="1.0416666666666665s" repeatCount="indefinite" keyTimes="0;1" values="0 50 52.3;360 50 52.3"></animateTransform>-->
      <!--        </path>-->
      <!--      </svg>-->
    </button>
  </form>
  <div class="notes">
    Already have an account? <a routerLink="/auth/login">Login</a>
  </div>
</div>
