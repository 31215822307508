import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss', '../auth.module.scss']
})
export class SetPasswordComponent implements OnInit {

  setPasswordForm: FormGroup;
  password: string;
  confirmPassword: string;
  validKey = false;
  noToken = false;

  ngOnInit(): void {
    this.setPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    });
  }

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private appRouter: Router, private auth: AuthService) {
    if (this.route.snapshot.params.token && this.route.snapshot.params.email) {
      // TODO: get email back
      this.auth.validateResetToken(this.route.snapshot.params.token, this.route.snapshot.params.email)
        .subscribe((userData) => {
          // console.log('valid reset token');
          this.validKey = true;
        }, (e: Error) => {
          this.noToken = true;
          console.error('failed to reset password');
        });
    } else {
      this.noToken = true;
    }
  }

  onSubmit() {
    if (this.setPasswordForm.valid && this.password === this.confirmPassword) {
      // console.log(`resetting`);
      this.auth.resetPassword(this.route.snapshot.params.token, this.route.snapshot.params.email, this.setPasswordForm.controls.password.value, this.setPasswordForm.controls.confirmPassword.value)
        .subscribe((userData) => {
          // console.log('reset success');
          this.appRouter.navigate(['/auth/login']);
        }, (e: Error) => {
          console.error('failed to reset password');
        });
    }
  }

}
