<nav class="sticky-bar" [class]="{'nav-active': toggle}">
  <ul>
    <li class="logo-cell">
      <img class="logo" src="assets/general/g.svg" alt="Geev">
    </li>
    <li class="user-row">
      <a [matMenuTriggerFor]="profileMenu">
        <span class="user" [style]="{'background-image': 'url('+logo+')'}" *ngIf="logo"></span>
        <span class="name" *ngIf="displayName">{{displayName}}</span>
      </a>
      <mat-menu class="actions-menu" #profileMenu="matMenu">
        <button mat-menu-item (click)="editProfile()">
          עריכת פרופיל
        </button>
        <button mat-menu-item (click)="logoutUser()">
          התנתק
        </button>
      </mat-menu>
    </li>
  </ul>
  <ul class="nav">
    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/" (click)="toggleMenu()">
        <i class="icon-dashboard"></i>
        <span class="text">דשבורד</span>
        <span class="decore top"></span>
        <span class="decore bottom"></span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/campaigns" (click)="toggleMenu()">
        <i class="icon-campaigns"></i>
        <span class="text">קמפיינים</span>
        <span class="decore top"></span>
        <span class="decore bottom"></span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/mailing" (click)="toggleMenu()">
        <i class="icon-mailing"></i>
        <span class="text">דיוור</span>
        <span class="decore top"></span>
        <span class="decore bottom"></span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/contacts" (click)="toggleMenu()">
        <i class="icon-user"></i>
        <span class="text">אנשי קשר</span>
        <span class="decore top"></span>
        <span class="decore bottom"></span>
      </a>
    </li>
    <li routerLinkActive="active">
      <a routerLink="/settings" (click)="toggleMenu()">
        <i class="icon-settings"></i>
        <span class="text">הגדרות</span>
        <span class="decore top"></span>
        <span class="decore bottom"></span>
      </a>
    </li>
  </ul>
</nav>
<!--<nav class="navbar navbar-expand-lg fixed-top">-->
<!--  <a class="navbar-brand" href="#">GEEV</a>-->

<!--&lt;!&ndash;  <div class="collapse navbar-collapse" id="navbarNav">&ndash;&gt;-->
<!--&lt;!&ndash;    <ul class="navbar-nav">&ndash;&gt;-->
<!--&lt;!&ndash;      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="nav-link" routerLink="/home"><span class="material-icons mr-3">home</span> Home</a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->
<!--&lt;!&ndash;    </ul>&ndash;&gt;-->
<!--&lt;!&ndash;  </div>&ndash;&gt;-->
<!--  <div class="pr profile-actions">-->
<!--    <button mat-icon-button [matMenuTriggerFor]="headerMenu" aria-label="Profile">-->
<!--      <mat-icon>apps</mat-icon>-->
<!--    </button>-->
<!--    <mat-menu #headerMenu="matMenu">-->
<!--      <button mat-menu-item (click)="goTo('/')">-->
<!--        <mat-icon>home</mat-icon>-->
<!--        <span>Dashboard</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="goTo('/campaigns')">-->
<!--        <mat-icon>person</mat-icon>-->
<!--        <span>Campaigns</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="goTo('/contacts')">-->
<!--        <mat-icon>person</mat-icon>-->
<!--        <span>Contacts</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="goTo('/mailing')">-->
<!--        <mat-icon>mail</mat-icon>-->
<!--        <span>Mailing</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="goTo('/settings')">-->
<!--        <mat-icon>settings</mat-icon>-->
<!--        <span>Settings</span>-->
<!--      </button>-->
<!--&lt;!&ndash;      <button mat-menu-item (click)="goTo('/home/profile/invoices')">&ndash;&gt;-->
<!--&lt;!&ndash;        <mat-icon>payments</mat-icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <span>Invoices</span>&ndash;&gt;-->
<!--&lt;!&ndash;      </button>&ndash;&gt;-->
<!--      <button mat-menu-item (click)="logoutUser()">-->
<!--        <mat-icon>exit_to_app</mat-icon>-->
<!--        <span>Logout</span>-->
<!--      </button>-->
<!--      &lt;!&ndash;              <button mat-menu-item (click)="checkStats(website)" [disabled]="!website.monitorId">&ndash;&gt;-->
<!--      &lt;!&ndash;                <mat-icon>refresh</mat-icon>&ndash;&gt;-->
<!--      &lt;!&ndash;                <span>Check status</span>&ndash;&gt;-->
<!--      &lt;!&ndash;              </button>&ndash;&gt;-->
<!--      &lt;!&ndash;              <button mat-menu-item>&ndash;&gt;-->
<!--      &lt;!&ndash;                <mat-icon>delete</mat-icon>&ndash;&gt;-->
<!--      &lt;!&ndash;                <span>Delete</span>&ndash;&gt;-->
<!--      &lt;!&ndash;              </button>&ndash;&gt;-->
<!--    </mat-menu>-->
<!--  </div>-->
<!--</nav>-->
